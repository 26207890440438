// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedJob: null
}

const webdrivers = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_WEB_DRIVERS':
      return {
        ...state,
        web_drivers: action.web_drivers,
        server_number: action.server_number
      }
    default:
      return { ...state }
  }
}
export default webdrivers
