// **  Initial State
const initialState = {
  userData: {},
  points: 'None',
  driver_session_id:'',
  driver_login_status: false,
  capture_file_name: ''

}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_LOADING':
      return {
        ...state,
        loading: action.loading
      } 
    case 'CAPTRE_STATUS':
      return {
        ...state,
        capture_file_name: action.capture_file_name,
        driver_session_id: action.driver_session_id
      } 
    case 'DRIVER_LOGIN_RESUT':
      return {
        ...state,
        capture_file_name: action.capture_file_name,
        driver_login_status: action.driver_login_status,
        driver_session_id: action.driver_session_id,
        points: action.points,
      }
    case 'LOGIN':
      return {
        ...state,
        userData: action.data,
        [action.config.storageTokenKeyName]: action[action.config.storageTokenKeyName],
        [action.config.storageRefreshTokenKeyName]: action[action.config.storageRefreshTokenKeyName]
      }
    case 'LOGOUT':
      const obj = { ...action }
      delete obj.type
      return { ...state, userData: {}, ...obj }
    default:
      return state
  }
}

export default authReducer
